<template>
  <div class="operation-home">
    <IndexHeader />
    <div class="operation-home-content">
      <div class="op-wrap">
        <el-button
          v-show="PCHSBtnIsShow && isExpandedIsShow"
          class="listSwitch commonBtn"
          type="primary"
          size="mini"
          @click="toggleContent"
        >
          <i v-show="isExpanded" class="el-icon-arrow-down"></i>
          <i v-show="!isExpanded" class="el-icon-arrow-up"></i>
          {{ isExpanded ? '收起手术列表' : '展开手术列表' }}
        </el-button>
        <el-button class="dateButtonLeft" size="mini" @click="DateYesterday" round>
          <i class="el-icon-arrow-left"></i>
        </el-button>
        <div class="input-form-item-content">
          <el-date-picker
            v-model="waitStudyInfo.date"
            type="date"
            align="center"
            @change="changeStudyApplyTime"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </div>
        <el-button class="dateButtonRight" size="mini" @click="DateTomorrow" round>
          <i class="el-icon-arrow-right"></i>
        </el-button>
        <el-button
          v-show="PCHSBtnIsShow && isExpandedIsShow"
          class="confirmSwitch"
          type="primary"
          size="mini"
          @click="handleConfirm"
        >
          <i class="el-icon-s-promotion"></i>
          确认排程
        </el-button>
        <el-button
          class="docNurSwitch commonBtn"
          type="primary"
          size="mini"
          v-show="PCHSBtnIsShow && docNurIsShow"
          @click="notificationMethod('all')"
        >
          <i class="el-icon-s-promotion"></i>
          通知医生护士
        </el-button>
        <el-button class="timeSwitch commonBtn" type="primary" size="mini" @click="getTimesBox">
          <i class="el-icon-sort"></i>
          {{ timeIsShow ? '不显示时间轴' : '显示时间轴' }}
        </el-button>
      </div>
      <div class="conditions-wrap" v-loading="loading">
        <div class="item-wrap" v-show="!docGroupIsShow">
          <div v-show="timeIsShow">
            <div class="timeBox">
              <div class="timeBox-header">
                <div class="timeBox-header-lab" v-for="(item, index) in labList" :key="index">
                  <div class="titleBox">
                    <img :src="roomImg" />
                    <div class="divider_span">
                      {{ item.deptName }}
                      {{ item.name }}
                      <span>
                        共{{ InfoList[item.id]?.length ? InfoList[item.id]?.length : '0' }}台
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="timeBox-content">
                <div class="timeBox-hours">
                  <div v-for="[hour, items] in hoursArray" :key="hour" class="timeBox-item">
                    <div class="timeBox-item-hours">
                      {{ hour + ': 00' }}
                    </div>
                    <div class="timeBox-item-info" v-for="(ele, k) in items" :key="k">
                      <div class="timeBox-item-info-hours" v-for="idx in 4"></div>
                    </div>
                  </div>
                </div>
                <div class="timeBox-lab-list pd120">
                  <div class="timeBox-box-content" v-for="(item, index) in labList" :key="index">
                    <div
                      style="background-color: #fff"
                      v-if="docGroupList[item.id]"
                      v-for="group in docGroupList[item.id]"
                      :key="group.examineDoctorId"
                      :id="'card' + group.cardId"
                    >
                      <div class="demo-card_content-item">
                        <div class="card-item-header">主刀医生：{{ group.examineDoctor }}</div>
                        <el-card
                          v-for="ele in group.elements"
                          :key="ele.operationId"
                          :body-style="{ padding: '15px' }"
                          class="card-doc"
                          @mouseenter.native="
                            cardEnter('DocTimeOp', ele.operationId, ele, group.cardId)
                          "
                          @mouseleave.native="
                            cardLeave('DocTimeOp', ele.operationId, ele, group.cardId)
                          "
                          :id="'cardItem' + ele.operationId"
                          :class="{
                            card_show: ele.timeConflict === 0,
                            card_showWaring: ele.timeConflict === 1,
                            card_dashed: ele.status === '待排程',
                            card_wait: ele.status === '未开始',
                            card_progress: ele.status === '进行中',
                            card_ended: ele.status === '已结束'
                          }"
                        >
                          <div slot="header" class="clearfix">
                            <div class="doc-card-info">
                              <div class="doc-card-info-label" v-show="ele.operateNumberTitle">
                                <el-tag
                                  type=""
                                  size="small"
                                  :style="
                                    ele.scheduleStatus === 2 ? 'text-decoration: line-through' : ''
                                  "
                                >
                                  {{ ele.operateNumberTitle }}
                                </el-tag>
                              </div>
                              <div
                                class="doc-card-info-label"
                                v-show="ele.patientName"
                                :style="
                                  ele.scheduleStatus === 2 ? 'text-decoration: line-through' : ''
                                "
                              >
                                {{ ele.patientName }}
                              </div>
                              <div class="doc-card-info-label" v-show="ele.studyStartDate">
                                {{ ele.studyStartDate?.substring(11, 16) }}开始
                              </div>
                              <div class="doc-card-info-label" v-show="ele.studyDuration">
                                {{ ele.studyDuration }}分钟
                              </div>
                            </div>
                            <el-tag
                              v-if="ele.scheduleStatus == 2"
                              class="tag"
                              type="info"
                              size="small"
                              style="font-weight: bold"
                              :style="
                                ele.scheduleStatus === 2 ? 'text-decoration: line-through' : ''
                              "
                            >
                              已取消
                            </el-tag>
                            <el-tag
                              v-else-if="ele.status == '待排程'"
                              class="tag"
                              type="info"
                              size="small"
                            >
                              {{ ele.status }}
                            </el-tag>
                            <el-tag
                              v-else-if="ele.status == '未开始'"
                              class="tag"
                              type="info"
                              size="small"
                              style="background: #dedede; font-weight: bold"
                            >
                              {{ ele.status }}
                            </el-tag>
                            <el-tag
                              v-else-if="ele.status == '进行中'"
                              class="tag"
                              type="info"
                              size="small"
                              style="background: #00d100; font-weight: bold"
                            >
                              {{ ele.status }}
                            </el-tag>
                            <el-tag
                              v-else-if="ele.status == '已完成'"
                              class="tag"
                              type="info"
                              size="small"
                              style="background: #eee; font-weight: bold"
                            >
                              {{ ele.status }}
                            </el-tag>
                            <el-tag
                              v-else-if="ele.status == '已结束'"
                              class="tag"
                              type="info"
                              size="small"
                              style="background: #eee; font-weight: bold"
                            >
                              {{ ele.status }}
                            </el-tag>
                            <el-tag
                              v-if="ele.status == '未开始' && ele.timeConflict === 1"
                              class="tag"
                              type="info"
                              size="small"
                              style="margin-left: 5px; background: #ffe341; font-weight: bold"
                            >
                              时间冲突
                            </el-tag>
                          </div>
                          <div
                            :id="'DocTimeOp' + ele.operationId"
                            class="cardButton"
                            style="display: none"
                          >
                            <el-button
                              type="primary"
                              size="mini"
                              circle
                              class="buttonContent"
                              icon="el-icon-edit"
                              @click="editInfo(ele)"
                            ></el-button>
                            <el-button
                              type="danger"
                              size="mini"
                              circle
                              class="buttonContent"
                              icon="el-icon-close"
                              @click="closeInfo(ele)"
                            ></el-button>
                          </div>
                          <div class="doc-item-card-body">
                            <div class="doc-item-card-body-label" v-show="ele.inPatientNo">
                              <span>住院号:</span>
                              {{ ele.inPatientNo }}
                            </div>
                            <div
                              class="doc-item-card-body-label"
                              :style="
                                ele.scheduleStatus === 2 ? 'text-decoration: line-through' : ''
                              "
                            >
                              <span>手术名称:</span>
                              {{
                                ele.examineName?.length > 15
                                  ? ele.examineName.substring(0, 14) + '...'
                                  : ele.examineName
                              }}
                            </div>
                            <div
                              class="doc-item-card-body-label"
                              :style="
                                ele.scheduleStatus === 2 ? 'text-decoration: line-through' : ''
                              "
                            >
                              <span>计划开始:</span>
                              {{ ele.planStudyDate?.substring(11, 16) }}
                            </div>
                            <div
                              class="doc-item-card-body-label"
                              v-show="ele.sickArea"
                              :style="
                                ele.scheduleStatus === 2 ? 'text-decoration: line-through' : ''
                              "
                            >
                              <span>科室:</span>
                              {{ ele.sickArea }}
                            </div>
                            <!-- <div class="doc-item-card-body-label" v-show="ele.hospital">
                              <span>院区:</span>
                              {{ ele.hospital }}
                            </div> -->
                            <div
                              class="doc-item-card-body-label"
                              v-show="ele.ward"
                              :style="
                                ele.scheduleStatus === 2 ? 'text-decoration: line-through' : ''
                              "
                            >
                              <span>病区:</span>
                              {{ ele.ward }}
                              {{ ele.bedNo }}
                            </div>
                          </div>
                        </el-card>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="item-wrap-doc" v-show="docGroupIsShow">
          <transition name="fade">
            <div v-if="isExpanded" class="item-box-card doc-card">
              <el-card class="scheduleCard" :body-style="{ padding: '10px 10px 0 10px' }">
                <div slot="header" class="clearfix">
                  <div class="clearfixPadding">
                    <span>手术列表</span>
                    <div v-show="PCHSBtnIsShow">
                      <el-button
                        class="commonBtn"
                        type="primary"
                        size="mini"
                        @click="autoOperationSchedule"
                      >
                        一键排程
                      </el-button>
                    </div>
                  </div>
                </div>
                <div class="demo-card_content" v-if="groupList.length > 0">
                  <draggable
                    handle=".mover"
                    v-model="groupList"
                    :group="{
                      name: 'docGroupList',
                      pull: 'clone',
                      put: false
                    }"
                    animation="300"
                    @end="docGroupOnEnd('list')"
                  >
                    <div
                      class="demo-card_content-item"
                      v-for="item in groupList"
                      :key="item.examineDoctorId"
                    >
                      <div class="card-item-header mover">主刀医生：{{ item.examineDoctor }}</div>
                      <draggable
                        v-model="item.elements"
                        class="card-item-content"
                        :group="{
                          name: 'docGroupList',
                          pull: 'clone',
                          put: false
                        }"
                        animation="300"
                        @end="docGroupOnEnd('elements')"
                      >
                        <transition-group style="min-height: 100px; display: block">
                          <el-card
                            v-for="(val, index) in item.elements"
                            :key="val.operationId"
                            :body-style="{ padding: '10px' }"
                            class="card-doc mover"
                          >
                            <div slot="header" class="clearfix">
                              <div class="doc-card-info">
                                <div class="doc-card-info-label" v-show="val.operateNumber">
                                  <el-tag type="" size="small">组{{ index + 1 }}</el-tag>
                                </div>
                                <div class="doc-card-info-label" v-show="val.patientName">
                                  {{ val.patientName }}
                                </div>
                              </div>
                              <el-tag
                                v-if="val.scheduleStatus == 2"
                                class="tag"
                                type="info"
                                size="small"
                                style="font-weight: bold"
                              >
                                已取消
                              </el-tag>
                              <el-tag
                                v-else-if="val.status == '待排程'"
                                class="tag"
                                type="info"
                                size="small"
                              >
                                {{ val.status }}
                              </el-tag>
                              <el-tag
                                v-else-if="val.status == '未开始'"
                                class="tag"
                                type="info"
                                size="small"
                                style="background: #dedede; font-weight: bold"
                              >
                                {{ val.status }}
                              </el-tag>
                              <el-tag
                                v-else-if="val.status == '进行中'"
                                class="tag"
                                type="info"
                                size="small"
                                style="background: #00d100; font-weight: bold"
                              >
                                {{ val.status }}
                              </el-tag>
                              <el-tag
                                v-else-if="val.status == '已完成'"
                                class="tag"
                                type="info"
                                size="small"
                                style="background: #eee; font-weight: bold"
                              >
                                {{ val.status }}
                              </el-tag>
                              <el-tag
                                v-else-if="val.status == '已结束'"
                                class="tag"
                                type="info"
                                size="small"
                                style="background: #eee; font-weight: bold"
                              >
                                {{ val.status }}
                              </el-tag>
                              <el-tag
                                v-if="val.status == '未开始' && val.timeConflict === 1"
                                class="tag"
                                type="info"
                                size="small"
                                style="margin-left: 5px; background: #ffe341; font-weight: bold"
                              >
                                时间冲突
                              </el-tag>
                            </div>

                            <div class="doc-item-card-body">
                              <div class="doc-item-card-body-label" v-show="val.inPatientNo">
                                <span>住院号:</span>
                                {{ val.inPatientNo }}
                              </div>
                              <div class="doc-item-card-body-label">
                                <span>手术名称:</span>
                                {{
                                  val.examineName?.length > 15
                                    ? val.examineName.substring(0, 14) + '...'
                                    : val.examineName
                                }}
                              </div>
                              <div class="doc-item-card-body-label">
                                <span>计划开始:</span>
                                {{ val.planStudyDate?.substring(11, 16) }}
                              </div>
                              <div class="doc-item-card-body-label" v-show="val.sickArea">
                                <span>科室:</span>
                                {{ val.sickArea }}
                              </div>
                              <!-- <div class="doc-item-card-body-label" v-show="val.hospital">
                              <span>院区:</span>
                              {{ val.hospital }}
                            </div> -->
                              <div class="doc-item-card-body-label" v-show="val.ward">
                                <span>病区:</span>
                                {{ val.ward }}
                                {{ val.bedNo }}
                              </div>
                            </div>
                          </el-card>
                        </transition-group>
                      </draggable>
                    </div>
                  </draggable>
                </div>
                <el-empty v-else description="暂无数据" />
              </el-card>
            </div>
          </transition>

          <div class="time-lab-list">
            <div v-for="(item, index) in labList" :key="index">
              <div class="item-box-content">
                <el-divider content-position="left">
                  <div class="titleBox">
                    <img :src="roomImg" />
                    <div class="divider_span">
                      {{ item.deptName }}
                      {{ item.name }}
                      <span>
                        共{{ InfoList[item.id]?.length ? InfoList[item.id]?.length : '0' }}台
                      </span>
                    </div>
                  </div>
                </el-divider>
                <draggable
                  handle=".mover"
                  v-model="docGroupList[item.id]"
                  group="docGroupList"
                  animation="300"
                  @change="onElementChange(item.id)"
                  @end="docGroupOnEnd('list')"
                >
                  <transition-group style="min-height: 100px; padding-bottom: 50px; display: block">
                    <div
                      v-for="group in docGroupList[item.id]"
                      :key="group.examineDoctorId"
                      style="cursor: move"
                    >
                      <div class="demo-card_content-item">
                        <div class="card-item-header mover">
                          主刀医生：{{ group.examineDoctor }}
                        </div>
                        <draggable
                          v-model="group.elements"
                          class="card-item-content"
                          group="docGroupList"
                          animation="300"
                          @end="docGroupOnEnd('elements')"
                          @sort="operationListSort(group.elements, item.id)"
                        >
                          <transition-group style="min-height: 100px; display: block">
                            <el-card
                              v-for="ele in group.elements"
                              :key="ele.operationId"
                              :body-style="{ padding: '15px' }"
                              class="card-doc mover"
                              @mouseenter.native="cardEnter('DocOp', ele.operationId, ele)"
                              @mouseleave.native="cardLeave('DocOp', ele.operationId, ele)"
                              :class="{
                                card_show: ele.timeConflict === 0,
                                card_showWaring: ele.timeConflict === 1,
                                card_dashed: ele.status === '待排程',
                                card_wait: ele.status === '未开始',
                                card_progress: ele.status === '进行中',
                                card_ended: ele.status === '已结束'
                              }"
                            >
                              <div slot="header" class="clearfix">
                                <div class="doc-card-info">
                                  <div class="doc-card-info-label" v-show="ele.operateNumberTitle">
                                    <el-tag
                                      size="small"
                                      @click="getTitleIsShow(ele)"
                                      v-if="ele.titleIsShow === 'false'"
                                      :style="
                                        ele.scheduleStatus === 2
                                          ? 'text-decoration: line-through'
                                          : ''
                                      "
                                    >
                                      {{ ele.operateNumberTitle }}
                                    </el-tag>
                                    <div v-else>
                                      <el-input
                                        v-model="ele.operateNumberTitle"
                                        @blur="blurTitleIsShow(ele)"
                                      ></el-input>
                                    </div>
                                  </div>
                                  <div
                                    class="doc-card-info-label"
                                    v-show="ele.patientName"
                                    :style="
                                      ele.scheduleStatus === 2
                                        ? 'text-decoration: line-through'
                                        : ''
                                    "
                                  >
                                    {{ ele.patientName }}
                                  </div>
                                  <div class="doc-card-info-label" v-show="ele.studyStartDate">
                                    {{ ele.studyStartDate?.substring(11, 16) }}开始
                                  </div>
                                  <div class="doc-card-info-label" v-show="ele.studyDuration">
                                    {{ ele.studyDuration }}分钟
                                  </div>
                                </div>
                                <el-tag
                                  v-if="ele.scheduleStatus == 2"
                                  class="tag"
                                  type="info"
                                  size="small"
                                  style="font-weight: bold"
                                  :style="
                                    ele.scheduleStatus === 2 ? 'text-decoration: line-through' : ''
                                  "
                                >
                                  已取消
                                </el-tag>
                                <el-tag
                                  v-else-if="ele.status == '待排程'"
                                  class="tag"
                                  type="info"
                                  size="small"
                                >
                                  {{ ele.status }}
                                </el-tag>
                                <el-tag
                                  v-else-if="ele.status == '未开始'"
                                  class="tag"
                                  type="info"
                                  size="small"
                                  style="background: #dedede; font-weight: bold"
                                >
                                  {{ ele.status }}
                                </el-tag>
                                <el-tag
                                  v-else-if="ele.status == '进行中'"
                                  class="tag"
                                  type="info"
                                  size="small"
                                  style="background: #00d100; font-weight: bold"
                                >
                                  {{ ele.status }}
                                </el-tag>
                                <el-tag
                                  v-else-if="ele.status == '已完成'"
                                  class="tag"
                                  type="info"
                                  size="small"
                                  style="background: #eee; font-weight: bold"
                                >
                                  {{ ele.status }}
                                </el-tag>
                                <el-tag
                                  v-else-if="ele.status == '已结束'"
                                  class="tag"
                                  type="info"
                                  size="small"
                                  style="background: #eee; font-weight: bold"
                                >
                                  {{ ele.status }}
                                </el-tag>
                                <el-tag
                                  v-if="ele.status == '未开始' && ele.timeConflict === 1"
                                  class="tag"
                                  type="info"
                                  size="small"
                                  style="margin-left: 5px; background: #ffe341; font-weight: bold"
                                >
                                  时间冲突
                                </el-tag>
                              </div>
                              <div
                                :id="'DocOp' + ele.operationId"
                                class="cardButton"
                                style="display: none"
                              >
                                <el-button
                                  type="primary"
                                  size="mini"
                                  circle
                                  class="buttonContent"
                                  icon="el-icon-edit"
                                  @click="editInfo(ele)"
                                ></el-button>
                                <el-button
                                  type="danger"
                                  size="mini"
                                  circle
                                  class="buttonContent"
                                  icon="el-icon-close"
                                  @click="closeInfo(ele)"
                                ></el-button>
                              </div>
                              <div class="doc-item-card-body">
                                <div class="doc-item-card-body-label" v-show="ele.inPatientNo">
                                  <span>住院号:</span>
                                  {{ ele.inPatientNo }}
                                </div>
                                <div
                                  class="doc-item-card-body-label"
                                  :style="
                                    ele.scheduleStatus === 2 ? 'text-decoration: line-through' : ''
                                  "
                                >
                                  <span>手术名称:</span>
                                  {{
                                    ele.examineName?.length > 15
                                      ? ele.examineName.substring(0, 14) + '...'
                                      : ele.examineName
                                  }}
                                </div>
                                <div
                                  class="doc-item-card-body-label"
                                  :style="
                                    ele.scheduleStatus === 2 ? 'text-decoration: line-through' : ''
                                  "
                                >
                                  <span>计划开始:</span>
                                  {{ ele.planStudyDate?.substring(11, 16) }}
                                </div>
                                <div
                                  class="doc-item-card-body-label"
                                  v-show="ele.sickArea"
                                  :style="
                                    ele.scheduleStatus === 2 ? 'text-decoration: line-through' : ''
                                  "
                                >
                                  <span>科室:</span>
                                  {{ ele.sickArea }}
                                </div>
                                <!-- <div class="doc-item-card-body-label" v-show="ele.hospital">
                                  <span>院区:</span>
                                  {{ ele.hospital }}
                                </div> -->
                                <div
                                  class="doc-item-card-body-label"
                                  v-show="ele.ward"
                                  :style="
                                    ele.scheduleStatus === 2 ? 'text-decoration: line-through' : ''
                                  "
                                >
                                  <span>病区:</span>
                                  {{ ele.ward }}
                                  {{ ele.bedNo }}
                                </div>
                              </div>
                            </el-card>
                          </transition-group>
                        </draggable>
                      </div>
                    </div>
                  </transition-group>
                </draggable>
              </div>
            </div>
          </div>
        </div>
      </div>
      <operation-room-dialog ref="operationRoomDialog" @save="_dialogSave"></operation-room-dialog>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

import IndexHeader from './IndexHeader.vue'
import OperationRoomDialog from './OperationRoomDialog.vue'
import DatePanel from '@/views/Menu/components/date-picker/index'

export default {
  name: 'OperationRoom',
  components: {
    draggable,
    IndexHeader,
    OperationRoomDialog,
    DatePanel
  },
  data() {
    return {
      roomImg: require('@/assets/img/room.png'),
      loading: false,
      timeIsShow: false,
      docGroupIsShow: true,
      docNurIsShow: true,
      isExpanded: true,
      isExpandedIsShow: true,
      PCHSBtnIsShow: false,
      sortCount: 0,
      waitStudyInfo: {
        date: ''
      },
      InfoList: [],
      groupList: [],
      docGroupList: {},
      multipleSelection: [],
      selectDate: '',
      tableHeight: null,
      labList: [],
      hoursArray: [],
      hoursList: [],
      labId: '',
      operateNumberTitle: ''
    }
  },
  watch: {
    // 监听事件
    'waitStudyInfo.date': function (val) {
      let now = new Date().getDate()
      let mon = new Date().getMonth() + 1
      let isThisMouth = this.formatUtcMouth(val)
      let isToday = this.formatUtcDay(val)
      let wrap = document.querySelector('.item-wrap')
      if (isThisMouth > mon) {
        wrap.style = 'padding-left:38.5rem;'
        if (this.docGroupIsShow === true) {
          this.docNurIsShow = true
          wrap.style = 'padding-left:38.5rem;display:none;'
        }
      } else if (isThisMouth == mon) {
        if (isToday >= now) {
          wrap.style = 'padding-left:38.5rem;'
          this.isExpanded = true
          this.isExpandedIsShow = true
          if (this.docGroupIsShow === true) {
            this.docNurIsShow = true
            wrap.style = 'padding-left:38.5rem;display:none;'
          }
        } else {
          this.docNurIsShow = false
          this.isExpanded = false
          this.isExpandedIsShow = false
          wrap.style = 'padding-left:0;'
          if (this.docGroupIsShow === true) {
            wrap.style = 'padding-left:0;display:none;'
          }
        }
      } else {
        this.docNurIsShow = false
        wrap.style = 'padding-left:0;'
        if (this.docGroupIsShow === true) {
          wrap.style = 'padding-left:0;display:none;'
        }
      }
      if (this.isExpanded) {
        setTimeout(() => {
          let divCard = document.querySelector('.scheduleCard')
          let wrap = document.querySelector('.item-wrap-doc')
          wrap.style = 'padding-left:38.5rem;'
          divCard.style.height = window.innerHeight - 130 + 'px'
          if (window.innerHeight - 140 < 800) {
            if (window.innerWidth < 1180) {
              divCard.style.height = window.innerHeight - 125 + 'px'
            }
          }
        }, 300)
      } else {
        let wrap = document.querySelector('.item-wrap-doc')
        wrap.style = 'padding-left:0;'
      }

      this.timeIsShow = false
    }
  },
  created() {
    this.waitStudyInfo.date = this.formatUtcDateTime()
    this.getNowTime()
    this.getHomeLabList()
    this.getOperationSchedule()
    this.getRoleCode()
  },
  mounted() {
    this.setScrollBar()
    // 浏览器缩放，更新ScrollBar位置
    window.addEventListener('resize', this.setScrollBar)
  },
  methods: {
    getRoleCode() {
      this.$api.get(`/v1/webconsole/user/roles`).then((res) => {
        if (res.data && res.data.data.length > 0) {
          res.data.data.forEach((ele) => {
            if (ele.roleCode === 'PCHS') {
              this.PCHSBtnIsShow = true
            }
          })
        }
      })
    },
    toggleContent() {
      this.isExpanded = !this.isExpanded
      if (this.isExpanded) {
        setTimeout(() => {
          let divCard = document.querySelector('.scheduleCard')
          let wrap = document.querySelector('.item-wrap-doc')
          wrap.style = 'padding-left:38.5rem;'
          divCard.style.height = window.innerHeight - 130 + 'px'
          if (window.innerHeight - 140 < 800) {
            if (window.innerWidth < 1180) {
              divCard.style.height = window.innerHeight - 125 + 'px'
            }
          }
        }, 300)
      } else {
        let wrap = document.querySelector('.item-wrap-doc')
        wrap.style = 'padding-left:0;'
      }
    },
    onElementChange(id) {
      if (!this.labId) {
        this.labId = id
      }
    },

    docGroupOnEnd(type) {
      if (type === 'list') {
        for (let i in this.docGroupList) {
          if (this.docGroupList[i].length) {
            let mergedGroups = {}
            let resultList = []
            this.docGroupList[i].forEach((ele, index) => {
              let examineDoctorId = ele.examineDoctorId
              let arr = []
              for (let j = 0; j < ele.elements.length; j++) {
                const item = ele.elements[j]
                if (item.elements) {
                  this.labId = i
                  let elements = JSON.parse(JSON.stringify(item.elements))
                  arr = [...arr, ...elements]
                } else {
                  arr.push(item)
                }
              }
              ele.elements = arr

              if (mergedGroups[examineDoctorId]) {
                // 如果mergedGroups中不存在这个examineDoctorId，直接添加
                mergedGroups[examineDoctorId + '-' + index] = {
                  examineDoctorId: examineDoctorId,
                  examineDoctor: ele.examineDoctor,
                  elements: ele.elements?.slice() // 确保复制一份elements
                }
                resultList.push(mergedGroups[examineDoctorId + '-' + index])
              } else {
                // 如果mergedGroups中不存在这个examineDoctorId，直接添加
                mergedGroups[examineDoctorId] = {
                  examineDoctorId: examineDoctorId,
                  examineDoctor: ele.examineDoctor,
                  elements: ele.elements?.slice() // 确保复制一份elements
                }
                resultList.push(mergedGroups[examineDoctorId])
              }
            })

            this.docGroupList[i] = resultList
          }
        }
      }
      if (type === 'elements') {
        for (let i in this.docGroupList) {
          if (this.docGroupList[i].length) {
            let mergedGroups = {}
            let resultList = []
            this.docGroupList[i] = this.docGroupList[i].map((ele) => {
              if (!ele.elements) {
                return {
                  examineDoctorId: ele.operationId,
                  examineDoctor: ele.examineDoctor,
                  elements: [ele] // 确保 elements 是一个数组
                }
              }
              return ele
            })

            this.docGroupList[i].forEach((ele) => {
              let examineDoctorId = ele.examineDoctorId
              if (mergedGroups[examineDoctorId]) {
                // 将当前ele的elements合并到mergedGroups中的elements
                mergedGroups[examineDoctorId].elements = [
                  ...mergedGroups[examineDoctorId].elements,
                  ...ele.elements
                ]

                // 去重
                const seen = new Set()
                mergedGroups[examineDoctorId].elements = mergedGroups[
                  examineDoctorId
                ].elements.filter((item) => {
                  if (seen.has(item.operationId)) {
                    return false
                  } else {
                    seen.add(item.operationId)
                    return true
                  }
                })
              } else {
                // 如果mergedGroups中不存在这个examineDoctorId，直接添加
                mergedGroups[examineDoctorId] = {
                  examineDoctorId: examineDoctorId,
                  examineDoctor: ele.examineDoctor,
                  elements: ele.elements.slice() // 确保复制一份elements
                }
                resultList.push(mergedGroups[examineDoctorId])
              }
            })

            this.docGroupList[i] = resultList
          }
        }
      }
      if (this.labId) {
        this.scheduleUpData()
      }

      this.$forceUpdate()
    },
    operationListSort(val, id) {
      this.sortCount++
      setTimeout(() => {
        if (this.sortCount === 1) {
          if (this.labId === '') {
            let isArr = false
            val.forEach((ele) => {
              if (ele.elements) {
                isArr = true
              }
            })
            if (!isArr && val.length > 0) {
              let arr = []
              this.docGroupList[id].forEach((ele) => {
                ele.elements.forEach((e) => {
                  arr.push(e)
                })
              })
              this.$api.post(`/v1/webconsole/study/lab/schedule/update/${id}`, arr).then(() => {
                this.getOperationSchedule()
              })
            }

            this.sortCount = 0
          }
        } else {
          if (this.sortCount === 2 && val.length > 0) {
            this.labId = id
            this.scheduleUpData()
            this.sortCount = 0
          }
        }
      }, 400)
    },

    scheduleUpData() {
      let arr = []
      if (this.docGroupList[this.labId].length > 0) {
        this.docGroupList[this.labId].forEach((ele) => {
          ele.elements.forEach((e) => {
            arr.push(e)
          })
        })

        this.$api
          .post(`/v1/webconsole/study/lab/schedule/update/${this.labId}`, arr)
          .then((res) => {
            this.labId = ''
            if (res.data.data === true) {
              this.getOperationSchedule()
            }
          })
          .catch(() => {
            this.getOperationSchedule()
          })
      }
    },

    getGroupList() {
      this.groupList = []
      this.$api
        .get(`/v1/webconsole/study/schedule/need?date=${this.waitStudyInfo.date}`)
        .then((res) => {
          if (res.data && res.data.data) {
            let list = []
            for (let i in res.data.data) {
              for (let j = 0; j < res.data.data[i].length; j++) {
                const element = res.data.data[i][j]
                list.push(element)
              }
            }

            // let groupMap = new Map() // 使用 Map 以保留顺序

            // for (let i = 0; i < list.length; i++) {
            //   const element = list[i]
            //   const examineDoctorId = element.examineDoctorId
            //   if (!groupMap.has(examineDoctorId)) {
            //     groupMap.set(examineDoctorId, {
            //       examineDoctorId: examineDoctorId,
            //       examineDoctor: element.examineDoctor,
            //       elements: []
            //     })
            //   }
            //   groupMap.get(examineDoctorId).elements.push(element)
            // }

            // // 将 Map 转换为数组以保留顺序
            // this.groupList = Array.from(groupMap.values())

            let listId = ''
            let count = 0
            for (let i = 0; i < list.length; i++) {
              const element = list[i]
              const examineDoctorId = element.examineDoctorId
              if (listId === examineDoctorId) {
                for (let k = 0; k < this.groupList.length; k++) {
                  const ele = this.groupList[k]
                  if (ele.examineDoctorId === listId && ele.count === count) {
                    ele.elements.push(element)
                  }
                }
              } else {
                listId = examineDoctorId
                count++
                this.groupList.push({
                  examineDoctorId: element.examineDoctorId,
                  examineDoctor: element.examineDoctor,
                  count: count,
                  elements: [element]
                })
              }
            }
          }
        })
    },
    getDocGroup() {
      this.loading = true
      setTimeout(() => {
        this.loading = false
        this.timeIsShow = false
        if (this.docGroupIsShow === true) {
          this.labList.forEach((ele) => {
            // this.docGroupList[ele.id] = []
            this.$set(this.docGroupList, ele.id, [])
          })
          for (let i in this.InfoList) {
            // let groupMap = new Map() // 使用 Map 以保留顺序

            // for (let j = 0; j < this.InfoList[i].length; j++) {
            //   const element = this.InfoList[i][j]
            //   const examineDoctorId = element.examineDoctorId
            //   element.titleIsShow = 'false'
            //   if (!groupMap.has(examineDoctorId)) {
            //     groupMap.set(examineDoctorId, {
            //       examineDoctorId: examineDoctorId,
            //       examineDoctor: element.examineDoctor,
            //       elements: []
            //     })
            //   }
            //   groupMap.get(examineDoctorId).elements.push(element)
            // }

            // // 将 Map 转换为数组以保留顺序
            // this.docGroupList[i] = Array.from(groupMap.values())
            let listId = ''
            let count = 0
            for (let j = 0; j < this.InfoList[i].length; j++) {
              const element = this.InfoList[i][j]
              const examineDoctorId = element.examineDoctorId
              element.titleIsShow = 'false'
              if (listId === examineDoctorId) {
                for (let k = 0; k < this.docGroupList[i].length; k++) {
                  const ele = this.docGroupList[i][k]
                  if (ele.examineDoctorId === listId && ele.count === count) {
                    ele.elements.push(element)
                  }
                }
              } else {
                listId = examineDoctorId
                count++
                this.docGroupList[i].push({
                  examineDoctorId: element.examineDoctorId,
                  examineDoctor: element.examineDoctor,
                  count: count,
                  elements: [element]
                })
              }
            }
          }
        }
      }, 300)
    },

    calculateTimeDifference(start, end) {
      const startDate = new Date(start)
      const endDate = new Date(end)

      // 计算时间差（毫秒）
      const timeDifference = endDate - startDate

      // 将时间差转换为分钟
      const minutesDifference = Math.floor(timeDifference / (1000 * 60))

      return minutesDifference
    },

    getTimesBox() {
      this.loading = true
      let wrap = document.querySelector('.item-wrap')
      wrap.style = 'padding-left:0;'
      this.$api
        .get(`/v1/webconsole/study/schedule/time/list?date=${this.waitStudyInfo.date}`)
        .then((res) => {
          this.InfoList = res.data.data
          this.labList.forEach((ele) => {
            this.$set(this.docGroupList, ele.id, [])
          })
          for (let i in this.InfoList) {
            // let groupMap = new Map() // 使用 Map 以保留顺序

            // for (let j = 0; j < this.InfoList[i].length; j++) {
            //   const element = this.InfoList[i][j]
            //   const examineDoctorId = element.examineDoctorId
            //   element.titleIsShow = 'false'
            //   if (!groupMap.has(examineDoctorId)) {
            //     groupMap.set(examineDoctorId, {
            //       examineDoctorId: examineDoctorId,
            //       cardId: element.operationId,
            //       examineDoctor: element.examineDoctor,
            //       elements: []
            //     })
            //   }
            //   groupMap.get(examineDoctorId).elements.push(element)
            // }

            // // 将 Map 转换为数组以保留顺序
            // this.docGroupList[i] = Array.from(groupMap.values())

            let listId = ''
            let count = 0
            for (let j = 0; j < this.InfoList[i].length; j++) {
              const element = this.InfoList[i][j]
              const examineDoctorId = element.examineDoctorId
              element.titleIsShow = 'false'
              if (listId === examineDoctorId) {
                for (let k = 0; k < this.docGroupList[i].length; k++) {
                  const ele = this.docGroupList[i][k]
                  if (ele.examineDoctorId === listId && ele.count === count) {
                    ele.elements.push(element)
                  }
                }
              } else {
                listId = examineDoctorId
                count++
                this.docGroupList[i].push({
                  examineDoctorId: element.examineDoctorId,
                  cardId: element.operationId,
                  examineDoctor: element.examineDoctor,
                  count: count,
                  elements: [element]
                })
              }
            }
          }
          setTimeout(() => {
            this.loading = false
            this.docGroupIsShow = false
            this.timeIsShow = !this.timeIsShow
            if (this.timeIsShow === true) {
              this.hoursList = []
              for (let i = 8; i < 25; i++) {
                const hour = ('0' + i).slice(-2)
                this.hoursList.push(hour)
              }
              const hoursObj = {}
              for (let i = 8; i < 25; i++) {
                const hour = ('0' + i).slice(-2)
                hoursObj[hour] = JSON.parse(JSON.stringify(this.labList))
              }

              if (this.InfoList) {
                for (let i in this.InfoList) {
                  for (let j = 0; j < this.InfoList[i].length; j++) {
                    const element = this.InfoList[i][j]
                    const result = this.calculateTimeDifference(
                      element.expectStart,
                      element.expectEnd
                    )
                    element.card_height = Math.floor(result / 5)
                    element.card_mt =
                      Number(element.expectStart?.substring(11, 13)) -
                      8 +
                      Number((Number(element.expectStart?.substring(14, 16)) / 60).toFixed(2))
                  }
                }
              }

              // 获取键值对数组，并按照小时进行排序
              const sortedEntries = Object.entries(hoursObj).sort((a, b) => {
                // 将小时字符串转换为数字进行比较
                return parseInt(a[0]) - parseInt(b[0])
              })

              this.hoursArray = sortedEntries
              this.getCardStyle()
            } else {
              this.docGroupIsShow = true
            }
          }, 300)
        })
    },
    getCardStyle() {
      for (let i in this.docGroupList) {
        for (let j = 0; j < this.docGroupList[i].length; j++) {
          const element = this.docGroupList[i][j]
          let id = 'card' + element.cardId
          document.getElementById(id).style.width = 405 + 'px'
          document.getElementById(id).style.margin = '0 6px'
          document.getElementById(id).style.position = 'absolute'
          document.getElementById(id).style.top = element.elements[0].card_mt * 160 + 4 + 'px'
          document.getElementById(id).style.zIndex = Math.floor(element.elements[0].card_mt) + 10
          element.elements.forEach((ele) => {
            let id2 = 'cardItem' + ele.operationId
            if (element.elements.length > 1) {
              document.getElementById(id2).style.height =
                Math.floor((ele.card_height * 40) / 3) - 30 + 'px'
            } else {
              document.getElementById(id2).style.height =
                Math.floor((ele.card_height * 40) / 3) + 'px'
            }
          })
        }
      }
    },

    getHomeLabList() {
      this.$api.get(`/v1/webconsole/study/rooms`).then((res) => {
        if (res.data.data.length > 0) {
          this.labList = res.data.data
        }
      })
    },
    cardEnter(type, Num, val, cardId) {
      if (val.scheduleStatus === 2) return
      if (this.isExpandedIsShow === false) return
      let id = type + Num
      document.getElementById(id).style.display = 'block'
      if (type === 'DocTimeOp') {
        let id2 = 'card' + cardId
        document.getElementById(id2).style.zIndex = 99
      }
    },
    cardLeave(type, Num, val, cardId) {
      if (val.scheduleStatus === 2) return
      if (this.isExpandedIsShow === false) return
      let id = type + Num
      document.getElementById(id).style.display = 'none'
      if (type === 'DocTimeOp') {
        let id2 = 'card' + cardId
        document.getElementById(id2).style.zIndex = 10
      }
    },

    getTitleIsShow(val) {
      if (val.scheduleStatus === 2) return
      if (this.isExpandedIsShow === false) return
      val.titleIsShow = 'true'
      this.operateNumberTitle = val.operateNumberTitle
      this.$forceUpdate()
    },
    blurTitleIsShow(val) {
      let data = {
        planStudyDate: val.planStudyDate || null,
        planStudyDuration: val.planStudyDuration || null,
        operateNumberTitle: val.operateNumberTitle || null
      }
      let formData = new FormData()
      for (let i in data) {
        const element = data[i]
        if (element !== null) {
          formData.append(i, element)
        }
      }
      this.$api
        .post(`/v1/webconsole/study/schedule/update/title/${val.id}`, formData)
        .then((res) => {
          if (res.data && res.data.data) {
            this.$message({
              message: '编辑成功',
              type: 'success'
            })
            this.getOperationSchedule()
          } else {
            this.$message({
              message: res.data.msg,
              type: 'warning'
            })
          }
        })
    },

    editInfo(val) {
      this.$refs.operationRoomDialog.Show(val)
    },
    closeInfo(val) {
      this.$confirm('是否取消排程?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this._close(val)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    _close(val) {
      this.$api.post(`/v1/webconsole/study/schedule/cancel/${val.id}`).then(() => {
        this.getOperationSchedule()
        return this.$message.success('取消排程成功')
      })
    },

    changeStudyApplyTime(val) {
      if (val) {
        this.loading = true
        this.waitStudyInfo.date = val
        this.selectDate = val
        this.docGroupIsShow = true
        this.getOperationSchedule()
      }
    },
    getOperationSchedule() {
      let url = ''
      url = `/v1/webconsole/study/schedule/list?date=${this.waitStudyInfo.date}`
      this.$api.get(url).then((res) => {
        this.loading = false
        if (this.docGroupIsShow === true) {
          this.getGroupList()
        }

        if (res.data && res.data.data) {
          this.InfoList = res.data.data
          if (this.docGroupIsShow === true) {
            this.getDocGroup()
          }
        } else {
          this.InfoList = []
        }
      })
    },
    _dialogSave() {
      this.getOperationSchedule()
    },
    // 将10位或者13位时间戳转化为日期格式
    timestampToTime(timestamp) {
      if (String(timestamp).length == 10) {
        var unixtimestamp = new Date(timestamp * 1000)
        var year = 1900 + unixtimestamp.getYear()
        var month = '0' + (unixtimestamp.getMonth() + 1)
        var date = '0' + unixtimestamp.getDate()
        var hour = '0' + unixtimestamp.getHours()
        var minute = '0' + unixtimestamp.getMinutes()
        var second = '0' + unixtimestamp.getSeconds()
        return (
          year +
          '-' +
          month.substring(month.length - 2, month.length) +
          '-' +
          date.substring(date.length - 2, date.length) +
          ' ' +
          hour.substring(hour.length - 2, hour.length) +
          ':' +
          minute.substring(minute.length - 2, minute.length) +
          ':' +
          second.substring(second.length - 2, second.length)
        )
      } else {
        let currentTime = new Date(timestamp)
        let year = currentTime.getFullYear(),
          month = currentTime.getMonth() + 1,
          day = currentTime.getDate(),
          hour = currentTime.getHours(),
          minute = currentTime.getMinutes(),
          second = currentTime.getSeconds(),
          millSeconds = currentTime.getMilliseconds()
        let months = month < 10 ? '0' + month : month,
          days = day < 10 ? '0' + day : day,
          hours = hour < 10 ? '0' + hour : hour,
          minutes = minute < 10 ? '0' + minute : minute,
          seconds = second < 10 ? '0' + second : second,
          millSecondss =
            millSeconds < 10
              ? '00' + millSeconds
              : millSeconds < 100
              ? '0' + millSeconds
              : millSeconds
        return (
          year +
          '-' +
          months +
          '-' +
          days +
          ' ' +
          hours +
          ':' +
          minutes +
          ':' +
          seconds +
          '.' +
          millSecondss
        )
      }
    },
    // 显示当前时间
    getNowTime() {
      let now = new Date(),
        year = now.getFullYear(), // 得到年份
        month = now.getMonth() + 1 < 10 ? '0' + (now.getMonth() + 1) : now.getMonth() + 1, // 得到月份
        date = now.getDate() // 得到日期
      let time = year + '-' + month + '-' + date
      this.selectDate = time
    },
    DateYesterday() {
      let date1 = new Date(this.selectDate).getTime() - 3600 * 1000 * 24
      this.selectDate = this.timestampToTime(date1).substring(0, 10)
      this.waitStudyInfo.date = this.selectDate

      this.loading = true
      this.docGroupIsShow = true

      this.getOperationSchedule()
    },
    DateTomorrow() {
      let date2 = new Date(this.selectDate).getTime() + 3600 * 1000 * 24
      this.selectDate = this.timestampToTime(date2).substring(0, 10)
      this.waitStudyInfo.date = this.selectDate

      this.loading = true
      this.docGroupIsShow = true

      this.getOperationSchedule()
    },
    handleConfirm() {
      this.$api
        .post(`/v1/webconsole/study/schedule/confirm?date=${this.waitStudyInfo.date}`)
        .then((res) => {
          this.$message({
            message: res.data.msg,
            type: 'success'
          })
        })
    },
    notificationMethod(type) {
      if (type === 'all') {
        this.$api
          .post(`/v1/webconsole/study/schedule/notify?date=${this.waitStudyInfo.date}`, [])
          .then((res) => {
            this.$message({
              message: res.data.msg,
              type: 'success'
            })
          })
      } else {
        if (this.multipleSelection.length > 0) {
          let operationIds = []
          for (let i = 0; i < this.multipleSelection.length; i++) {
            const element = this.multipleSelection[i]
            operationIds.push(element.operationId)
          }
          this.$api.post(`/v1/webconsole/study/schedule/notify`, operationIds).then((res) => {
            this.$message({
              message: res.data.msg,
              type: 'success'
            })
          })
        } else {
          this.$message({
            message: '请先选择一条信息',
            type: 'warning'
          })
        }
      }
    },

    // 一键排程
    autoOperationSchedule() {
      this.axios
        .post(`/v1/webconsole/study/schedule/auto_arrange?date=${this.waitStudyInfo.date}`)
        .then((res) => {
          this.loading = true
          if (res.data.status == 200) {
            this.getOperationSchedule()
          }
          return this.$message.warning(res.data.msg)
        })
    },
    setScrollBar() {
      let div = document.querySelector('.conditions-wrap')
      let divCard = document.querySelector('.scheduleCard')
      div.style.height = window.innerHeight - 106 + 'px'
      div.style.width = window.innerWidth + 'px'
      divCard.style.height = window.innerHeight - 130 + 'px'
      if (window.innerHeight - 140 < 800) {
        this.tableHeight = 325
        if (window.innerWidth < 1180) {
          divCard.style.height = window.innerHeight - 125 + 'px'
          this.tableHeight = 260
        }
      } else {
        this.tableHeight = 350
      }
    },
    formatUtcDateTime() {
      let date = new Date()
      return (
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) +
        '-' +
        (date.getDate() < 10 ? '0' + date.getDate() : date.getDate())
      )
    },
    formatUtcMouth(v) {
      if (v === null) {
        return ''
      }
      let date = new Date(v)
      return date.getMonth() + 1
    },
    formatUtcDay(v) {
      if (v === null) {
        return ''
      }
      let date = new Date(v)
      return date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    },
    formatUtcTime(v) {
      if (v === null) {
        return ''
      }
      let date = new Date(v)
      return (
        date.getHours() +
        ':' +
        (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes())
      )
    }
  }
}
</script>

<style scoped lang="less">
/* 添加一些样式，使内容更明显 */
.content {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ddd;
}

/* 定义过渡效果 */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.pd120 {
  padding-left: 120px;
}
.op-wrap {
  padding: 0 20px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 2px #eee;
  .dateButtonLeft {
    margin-right: 10px;
    i {
      font-weight: 600;
    }
  }
  .dateButtonRight {
    margin-left: 10px;
    i {
      font-weight: 600;
    }
  }
  .listSwitch {
    position: absolute;
    left: 20px;
  }
  .timeSwitch {
    position: absolute;
    right: 20px;
  }
  .docNurSwitch {
    position: absolute;
    right: 140px;
  }
  .confirmSwitch {
    position: absolute;
    right: 270px;
  }
}

.conditions-wrap::-webkit-scrollbar {
  height: 12px;
  width: 12px;
}

.conditions-wrap::-webkit-scrollbar-track {
  border-radius: 10px;
}
.conditions-wrap::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}
.conditions-wrap::-webkit-scrollbar-thumb:hover {
  background: rgba(157, 165, 183, 0.7);
}
.conditions-wrap::-webkit-scrollbar-thumb:window-inactive {
  background: #ccc;
}

.clearfix {
  display: flex;
  align-items: center;
}
.doc-card-info {
  display: flex;
  align-items: center;
  .doc-card-info-label {
    padding: 7px 7px 7px 0;
    .el-input {
      width: 80px;
    }
  }
  font-size: 18px;
  font-weight: bold;
  height: 30px;
}
.cardButton {
  position: absolute;
  right: 4px;
  top: 4px;
  .buttonContent {
    margin-top: 6px;
    padding: 7px 8px;
  }
}

.card_show:hover {
  box-shadow: 0 4px 14px 0 rgb(0 0 0 / 10%);
  scale: 1.03;
}
.card_show {
  .headerItem {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    .item-card-content {
      padding-right: 10px;
      font-size: 16px;
      font-weight: 600;
      span {
        margin-right: 4px;
      }
    }
  }
  .cardButton {
    position: absolute;
    right: 4px;
    .buttonContent {
      margin-top: 6px;
      padding: 7px 8px;
    }
  }
}

.card_dashed:hover {
  box-shadow: 0 4px 14px 0 rgb(0 0 0 / 10%);
  scale: 1.03;
}
.card_dashed {
  border: 5px dashed rgba(0, 0, 0, 0.7);
  background: linear-gradient(white, white) padding-box,
    repeating-linear-gradient(-45deg, #ccc 0, #ccc 0.5em, white 0, white 0.75em);
  .headerItem {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    .item-card-content {
      padding-right: 10px;
      font-size: 16px;
      font-weight: 600;
      span {
        margin-right: 4px;
      }
    }
  }
  .cardButton {
    position: absolute;
    right: 4px;
    .buttonContent {
      margin-top: 6px;
      padding: 7px 8px;
    }
  }
}
.card_wait:hover {
  box-shadow: 0 4px 14px 0 rgb(0 0 0 / 10%);
  scale: 1.03;
}
.card_wait {
  // background-color: #bebebe;
  .headerItem {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    .item-card-content {
      padding-right: 10px;
      font-size: 16px;
      font-weight: 600;
      span {
        margin-right: 4px;
      }
    }
  }
  .cardButton {
    position: absolute;
    right: 4px;
    .buttonContent {
      margin-top: 6px;
      padding: 7px 8px;
    }
  }
}
.card_progress:hover {
  box-shadow: 0 4px 14px 0 rgb(0 0 0 / 10%);
  scale: 1.03;
}
.card_progress {
  // background-color: #00d100;
  .headerItem {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    .item-card-content {
      padding-right: 10px;
      font-size: 16px;
      font-weight: 600;
      span {
        margin-right: 4px;
      }
    }
  }
  .cardButton {
    position: absolute;
    right: 4px;
    .buttonContent {
      margin-top: 6px;
      padding: 7px 8px;
    }
  }
}
.card_ended:hover {
  box-shadow: 0 4px 14px 0 rgb(0 0 0 / 10%);
  scale: 1.03;
}
.card_ended {
  // background-color: #f25959;
  .headerItem {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    .item-card-content {
      padding-right: 10px;
      font-size: 16px;
      font-weight: 600;
      span {
        margin-right: 4px;
      }
    }
  }
  .cardButton {
    position: absolute;
    right: 4px;
    .buttonContent {
      margin-top: 6px;
      padding: 7px 8px;
    }
  }
}
.card_showWaring:hover {
  box-shadow: 0 4px 14px 0 rgb(0 0 0 / 10%);
  scale: 1.03;
}
.card_showWaring {
  // background-color: #ffe341;
  .headerItem {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    .item-card-content {
      padding-right: 10px;
      font-size: 16px;
      font-weight: 600;
      span {
        margin-right: 4px;
      }
    }
  }
  .cardButton {
    position: absolute;
    right: 4px;
    .buttonContent {
      margin-top: 6px;
      padding: 7px 8px;
    }
  }
}

.card-doc:hover {
  box-shadow: 0 4px 14px 0 rgb(0 0 0 / 10%);
  scale: 1.03;
}
.card-item-header {
  height: 52px;
  line-height: 52px;
  padding: 0 20px;
  border-bottom: 1px solid #eee;
  font-size: 17px;
  font-weight: bold;
  background-color: #dde3e9;
}
.card-item-header:hover {
  background-color: #cedeef;
  cursor: pointer;
}
.card-item-content {
  padding: 10px;
  .el-card {
    margin-bottom: 5px;
  }
  /deep/.el-card__header {
    padding: 15px 15px 5px 15px;
  }

  .cardButton {
    position: absolute;
    right: 6px;
    top: 4px;
    .buttonContent {
      margin-top: 6px;
      padding: 7px 8px;
    }
  }

  .doc-item-card-body {
    display: flex;
    flex-direction: column;
    .doc-item-card-body-label {
      padding: 0 10px;
      font-size: 16px;
    }
  }
}

.conditions-wrap {
  position: relative;
  overflow: hidden;
  overflow-x: auto;
  overflow-y: auto;
  white-space: nowrap;

  .item-box-card {
    position: fixed;
    top: 106px;
    left: 0;
    z-index: 999;
    width: 624px;
    .scheduleCard {
      margin: -1px 10px 10px 0;
      position: relative;
      /deep/.el-card__header {
        padding: 5px 10px 5px 5px;
      }
      .demo-card_content {
        width: 100%;
        .scheduleTable {
          width: 100%;
          border-left: none;
        }
        .demo-card_content-item {
          margin-bottom: 12px;
          cursor: move;
          border: 1px #aeaeae solid;
          border-radius: 5px;
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        }
      }
    }

    .clearfix:before,
    .clearfix:after {
      display: table;
      content: '';
    }
    .clearfix:after {
      clear: both;
    }
    .clearfix {
      .clearfixPadding {
        padding: 7px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
          font-size: 18px;
          font-weight: 600;
          height: 30px;
          line-height: 30px;
        }
      }
      @media screen and (max-width: 1180px) {
        .clearfixPadding {
          padding: 5px 5px 5px 15px;
        }
      }
    }
  }
  @media screen and (max-width: 1180px) {
    .item-box-card {
      position: fixed;
      top: 130px;
      left: 0;
      z-index: 999;
    }
  }

  .demo-card_content::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }

  .doc-card .demo-card_content {
    max-height: calc(100vh - 15rem);
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    overflow-x: hidden;
    overflow-y: auto;
  }
  .doc-card /deep/.el-card__header {
    padding: 10px 15px;
  }

  .item-wrap,
  .item-wrap-doc {
    display: flex;
    padding-left: 625px;
    .time-lab-list {
      display: flex;
      .item-box-content {
        .demo-card_content-item {
          margin-bottom: 12px;
          cursor: move;
          border: 1px #aeaeae solid;
          border-radius: 5px;
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        }
        .card-doc:hover {
          box-shadow: 0 4px 14px 0 rgb(0 0 0 / 10%);
          scale: 1.03;
        }
        .card-item-header {
          height: 52px;
          line-height: 52px;
          padding: 0 20px;
          border-bottom: 1px solid #eee;
          font-size: 17px;
          font-weight: bold;
          background-color: #dde3e9;
        }
        .card-item-header:hover {
          background-color: #cedeef;
          cursor: pointer;
        }
        .card-item-content {
          padding: 10px;
          .el-card {
            margin-bottom: 5px;
            .tag {
              position: relative;
              top: 0;
              left: 4px;
            }
          }
          /deep/.el-card__header {
            padding: 10px;
          }
          .doc-item-card-body {
            display: flex;
            flex-direction: column;
            .doc-item-card-body-label {
              padding: 0 10px;
              margin-bottom: 5px;
              font-size: 16px;
            }
          }
        }
      }
    }
    .timeBox {
      margin-left: 30px;
      display: flex;
      flex-direction: column;
      height: calc(100vh - 120px);
      .timeBox-header {
        padding-left: 120px;
        display: flex;
        .timeBox-header-lab {
          width: 425px;
          background: #fff;
        }
        .titleBox {
          display: flex;
          padding: 5px 10px;
          img {
            width: 40px;
            height: 40px;
          }
          .divider_span {
            font-size: 1.17rem;
            font-weight: 600;
            margin-left: 7px;
            height: 40px;
            line-height: 40px;
            span {
              font-size: 1rem;
              height: 40px;
              line-height: 40px;
              color: #999;
            }
          }
        }
      }
      .timeBox-content {
        flex: 1;
        overflow: auto;
        position: relative;
      }
      .timeBox-lab-list {
        display: flex;
        position: absolute;
        top: 0;
        .timeBox-box-content {
          width: 425px;
          .demo-card_content-item {
            margin-bottom: 12px;
            cursor: move;
            border: 1px #aeaeae solid;
            border-radius: 5px;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            .card-doc:hover {
              box-shadow: 0 4px 14px 0 rgb(0 0 0 / 10%);
              scale: 1.03;
            }
            .card-item-header {
              height: 52px;
              line-height: 52px;
              padding: 0 20px;
              border-bottom: 1px solid #eee;
              font-size: 17px;
              font-weight: bold;
              background-color: #dde3e9;
            }
            .card-item-header:hover {
              background-color: #cedeef;
              cursor: pointer;
            }
            .card-item-content {
              padding: 10px;
              .el-card {
                margin-bottom: 5px;
              }
              /deep/.el-card__header {
                padding: 15px 15px 5px 15px;
              }
              .cardButton {
                position: absolute;
                right: 6px;
                top: 4px;
                .buttonContent {
                  margin-top: 6px;
                  padding: 7px 8px;
                }
              }
              .doc-item-card-body {
                display: flex;
                flex-direction: column;
                .doc-item-card-body-label {
                  padding: 0 10px;
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
      .timeBox-item {
        display: flex;
        align-items: center;
        border-top: 1px #cfcfcf solid;
        border-left: 1px #cfcfcf solid;
        border-right: 1px #cfcfcf solid;
      }
      .timeBox-item:first-child {
        border-top: none;
      }
      .timeBox-item-hours {
        width: 120px;
        text-align: center;
        font-size: 20px;
        font-weight: bold;
      }
      .timeBox-item-info {
        width: 425px;
        .timeBox-item-info-hours {
          height: 40px;
          border-left: 1px #cfcfcf solid;
          border-bottom: 1px #cfcfcf solid;
          margin-bottom: -0.8px;
        }
      }

      /* 使用属性选择器为最后一个小时段添加 border-bottom 样式 */
      .timeBox-item:last-child {
        border-bottom: 1px solid black;
      }
    }
    .item-box-content {
      width: 400px;
      display: inline-grid;
      margin: 10px 15px;
      .el-card /deep/ .el-card__header {
        padding: 10px 0 10px 20px;
      }
      .titleBox {
        display: flex;
        img {
          width: 40px;
          height: 40px;
        }
        .divider_span {
          font-size: 1.17rem;
          font-weight: 600;
          margin-left: 7px;
          height: 40px;
          line-height: 40px;
          span {
            font-size: 1rem;
            height: 40px;
            line-height: 40px;
            color: #999;
          }
        }
      }
      .el-card {
        margin-bottom: 10px;
        position: relative;
        .tag {
          position: absolute;
          top: 7px;
          right: 4px;
        }
      }
    }
  }
}
.input-form-item-content {
  width: 180px;
  .el-date-editor.el-input {
    width: 180px;
  }
}
</style>
