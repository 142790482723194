<style scoped lang="less">
.button-panel {
  width: 100%;
  bottom: 0;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
}
.top-item-row {
  margin: 10px 15px;
  display: flex;
  justify-content: space-between;
}
.item-row {
  margin: 1rem 4rem;
  width: 70%;
  display: flex;
  justify-content: flex-end;
}
.item-row > span {
  width: 40%;
  margin: 5px 0;
}
.input-form-item-content {
  width: 220px;
  .el-date-editor.el-input {
    width: 180px;
  }
}
</style>

<template>
  <CmDialogBorder
    size="mini"
    :zIndex="zIndex"
    :title="waitStudyInfoTitle"
    v-show="isShow"
    @close="Close"
  >
    <div class="top-item-row">
      <div class="item-row">
        <span>手术日期</span>
        <div class="input-form-item-content">
          <el-date-picker
            v-model="waitInfoDate"
            type="date"
            align="center"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            disabled
          ></el-date-picker>
        </div>
      </div>
    </div>
    <!-- <div class="top-item-row">
      <div class="item-row">
        <span>手术室</span>
        <div class="input-form-item-content">
          <el-select v-model="waitStudyInfo.labId" @change="getLab" placeholder="请选择">
            <el-option
              v-for="(item, index) in labList"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
    </div> -->
    <div class="top-item-row">
      <div class="item-row">
        <span>手术时间</span>
        <el-time-picker
          v-model="waitInfoTime"
          align="center"
          format="HH:mm"
          value-format="HH:mm"
        ></el-time-picker>
      </div>
    </div>
    <div class="top-item-row">
      <div class="item-row">
        <span>预计时长</span>
        <div class="input-form-item-content">
          <el-input type="text" v-model="waitStudyInfo.planStudyDuration" size="mini">
            <template slot="append">min</template>
          </el-input>
        </div>
      </div>
    </div>
    <div class="top-item-row">
      <div class="item-row">
        <span>台次</span>
        <div class="input-form-item-content">
          <el-input type="text" v-model="waitStudyInfo.operateNumberTitle" size="mini"></el-input>
        </div>
      </div>
    </div>

    <div class="button-panel">
      <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="Save"
      >
        保存
      </el-button>
      <el-button
        type="danger"
        class="common-button commonBtn"
        icon="el-icon-close"
        v-debounce="Close"
      >
        关闭
      </el-button>
    </div>
  </CmDialogBorder>
</template>

<script>
import CmDialogBorder from './CmDialogBorder.vue'
import { PopupManager } from 'element-ui/lib/utils/popup'
import deepClone from '../plugins/clone'

export default {
  name: 'OperationRoomDialog',
  components: {
    CmDialogBorder
  },
  data() {
    return {
      zIndex: PopupManager.nextZIndex(),
      isShow: false,
      operationId: '',
      scheduleId: '',
      waitStudyInfoTitle: '手术排程',
      waitStudyInfo: {
        lab: '',
        labId: '',
        planStudyDate: '',
        planStudyDuration: '',
        operateNumberTitle: '',
        status: ''
      },
      waitInfoDate: '',
      waitInfoTime: '',
      labList: []
    }
  },
  watch: {},
  created() {},
  methods: {
    getLab(val) {
      for (let i = 0; i < this.labList.length; i++) {
        const element = this.labList[i]
        if (element.value === val) {
          this.waitStudyInfo.lab = element.label
          break
        }
      }
    },
    formatUtcDate(v) {
      let date = v ? new Date(v) : new Date()
      return (
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) +
        '-' +
        (date.getDate() < 10 ? '0' + date.getDate() : date.getDate())
      )
    },
    formatUtcTime(v) {
      let date = v ? new Date(v) : new Date()
      return (
        date.getHours() +
        ':' +
        (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes())
      )
    },
    getHomeLabList() {
      this.$api.get(`/v1/webconsole/study/rooms`).then((res) => {
        let labArr = []
        if (res.data.data.length > 0) {
          res.data.data.map((item) => {
            labArr.push({
              label: item.deptName ? item.deptName + item.name : item.name,
              value: item.id
            })
          })
          this.labList = labArr
        }
      })
    },
    Show(bloodVessel) {
      if (bloodVessel) {
        console.log(bloodVessel)
        this.operationId = deepClone(bloodVessel.operationId)
        this.scheduleId = deepClone(bloodVessel.id)
        this.waitStudyInfo.planStudyDuration = deepClone(bloodVessel.planStudyDuration)
        this.waitStudyInfo.operateNumberTitle = deepClone(bloodVessel.operateNumberTitle)
        this.waitStudyInfo.operateNumber = deepClone(bloodVessel.operateNumber)
        if (bloodVessel.status == '待排程') {
          this.waitStudyInfoTitle = '手术拟排程'
          this.waitStudyInfo.status = deepClone(bloodVessel.status)
          this.waitStudyInfo.lab = deepClone(bloodVessel.tempLab)
          this.waitStudyInfo.labId = deepClone(bloodVessel.tempLabId)
          this.waitStudyInfo.planStudyDate = deepClone(bloodVessel.tempStudyDate)
        } else {
          this.waitStudyInfoTitle = '手术排程'
          this.waitStudyInfo.lab = deepClone(bloodVessel.lab)
          this.waitStudyInfo.labId = deepClone(bloodVessel.labId)
          this.waitStudyInfo.planStudyDate = deepClone(bloodVessel.planStudyDate)
        }
        if (this.waitStudyInfo.planStudyDate) {
          this.waitInfoDate = this.formatUtcDate(this.waitStudyInfo.planStudyDate)
          this.waitInfoTime = this.formatUtcTime(this.waitStudyInfo.planStudyDate)
        } else {
          this.waitInfoDate = this.formatUtcDate()
          this.waitInfoTime = this.formatUtcTime()
        }
      }

      this.isShow = true
      this.getHomeLabList()
    },
    Save() {
      this.waitStudyInfo.planStudyDate = this.waitInfoDate + ' ' + this.waitInfoTime

      let data = {
        planStudyDate: this.waitStudyInfo.planStudyDate || null,
        planStudyDuration: this.waitStudyInfo.planStudyDuration || 90,
        operateNumberTitle: this.waitStudyInfo.operateNumberTitle || null
      }

      let formData = new FormData()
      for (let i in data) {
        const element = data[i]
        if (element !== null) {
          formData.append(i, element)
        }
      }
      this.$api
        .post(`/v1/webconsole/study/schedule/update/${this.scheduleId}`, formData)
        .then((res) => {
          if (res.data && res.data.data) {
            this.$message({
              message: '保存成功',
              type: 'success'
            })
            this.$emit('save')
          } else {
            this.$message({
              message: res.data.msg,
              type: 'warning'
            })
          }
        })

        .finally(() => {
          this.Close()
        })
    },

    Close() {
      this.isShow = false
      this.operationId = ''
      this.scheduleId = ''
      this.waitInfoDate = ''
      this.waitInfoTime = ''
      this.waitStudyInfo = {
        lab: '',
        labId: '',
        planStudyDate: '',
        planStudyDuration: '',
        operateNumberTitle: '',
        status: ''
      }
    }
  }
}
</script>
